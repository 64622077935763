import React, { Component } from 'react';
import { BrowserRouter as Router, Route, Switch } from 'react-router-dom';
import ic_launcher from './images/ic_launcher.png';
import './css/custom.css';

import WarrantyWrapper from './js/components/warranty/warrantywrapper.js';

export default class App extends Component {
  constructor(props) {
    super(props);
    this.state = {};
    this.state.loading = false;
  }

  /**
   * Component Did Mount
   */

  async componentDidMount() {}

  /**
   * Render
   */

  render() {
    return (
      <div>
        <head>
			      {/* META */}
			      <meta charSet="utf-8"/>
			      <meta name="viewport" content="width=device-width,initial-scale=1"/>
			      {/* Optimize mobile viewport */}
			      <title>XCSpec Login</title>
			      {/* SCROLLS */}
			      <link rel="stylesheet" href="//netdna.bootstrapcdn.com/bootstrap/3.0.0/css/bootstrap.min.css"/>
			      {/* load bootstrap */}
			      <link rel="stylesheet" href="/stylesheets/login.css"/>

			      {/* SPELLS */}<script src="//ajax.googleapis.com/ajax/libs/jquery/2.0.3/jquery.min.js"></script>{/* load jquery */}<script src="//ajax.googleapis.com/ajax/libs/angularjs/1.0.8/angular.min.js"></script>{/* load angular */}
			   </head>
         <div style={{display: "block", height: "50px", padding:"0"}} className="navbar navbar-inverse navbar-static-top">
			         <a className="navbar-brand" style={{paddingTop: "0px"}}><img className="brand-image logo img-responsive" src={ic_launcher}/></a>
			         <p className="navbar-text">XCSpec</p>
			      </div>
      <div className="container">
        
        <WarrantyWrapper/>
      </div>
      <div className="navbar navbar-default navbar-bottom">
			         <div style={{display: "block"}} >
			            <p className="navbar-text text-center navbar-right">Copyright 2023 XCSpec Inc. Larkspur, CA. All rights reserved</p>
			         </div>
			      </div>
      </div>
      
    //     <Router>
    //   <Switch>
    //     <Route path="/register" component={WarrantyWrapper} />
    //   </Switch>
    // </Router>
    );
  }
}
