import XPanel from './XPanel'
import XcContent from './XcContent'
import Title from './Title'
import Content from './Content'
import MenuItem from 'react-bootstrap'

export {
  XPanel,
  XcContent,
  Title,
  Content,
  MenuItem
}

export default XPanel
