import React, { useState } from "react";
import { Button, Form, Spinner, Alert, Row, Col } from "react-bootstrap";

import {XPanel, PageTitle} from '../';
import WarrantyRegistration from "./warrantyregistration";

class WarrantyWrapper extends React.Component{

    constructor(props){
		super(props);
	}
    render(){
        return (
            <Row>
                <XPanel style={{paddingLeft: "30px", paddingRight: "30px"}}>
                <XPanel.Title title={"AQ Indoor Warranty Registration / Alerts Setup"}>
                    
                </XPanel.Title>
                <XPanel.Content>
                    <Col md={ 12 } sm={ 12 } xs={ 12 }>
                        <WarrantyRegistration/>
                    </Col>
                </XPanel.Content>
                </XPanel>
            </Row>
        );
    }
};

export default WarrantyWrapper;
