// Import necessary packages and components
import React, { useState, useEffect, useRef } from 'react'; // <-- Added useRef
import { Button, Form, Spinner, Alert } from 'react-bootstrap';
import { FaCheckCircle, FaExclamationTriangle } from 'react-icons/fa';
import axios from 'axios';
import 'bootstrap/dist/css/bootstrap.min.css';
import './warrantyregistration.css'; // Import the CSS

// Define the WarrantyRegistration component
const WarrantyRegistration = (deviceName) => {
  const queryParams = new URLSearchParams(window.location.search);
  const id = queryParams.get('id');
  const mac = queryParams.get('mac');

  const maxNumberOfEmails = 3;
  const maxNumberOfPhoneNumbers = 3;

  const [formData, setFormData] = useState({
    id: id || '', // populate with id from URL parameters if it exists
    macAddress: mac || '', // populate with mac from URL parameters if it exists
    emails: [''],
    companyName: '',
    customerName: '',
    phoneNumbers: [''], // Optional phone number
    enableShortTermAlert: false,
    enableLongTermAlert: false,
  });

  const [formErrors, setFormErrors] = useState({
    emails: [''],
    companyName: '',
    customerName: '',
    phoneNumbers: [''], // Optional phone number error
    id: '',
  });

  const [isDisabled, setIsDisabled] = useState(false);
  const [loading, setLoading] = useState(false);
  const [message, setMessage] = useState(null);
  const [isError, setIsError] = useState(false);
  const [isFormSubmitted, setIsFormSubmitted] = useState(false);
  // Ref to track form submission
  const formSubmittedRef = useRef(false);

  // Create refs for each field
  const emailRefs = useRef([]);
  const phoneNumberRefs = useRef([]);
  const companyNameRef = useRef(null);
  const customerNameRef = useRef(null);
  const idRef = useRef(null);
  const macAddressRef = useRef(null);
  const shortTermThresholdRef = useRef(null);
  const shortTermThresholdDurationRef = useRef(null);
  const longTermThresholdRef = useRef(null);
  const longTermThresholdDurationRef = useRef(null);


  const addEmail = () => {
    if (formData.emails.length <= maxNumberOfEmails) {
      setFormData({ ...formData, emails: [...formData.emails, ''] });
      setFormErrors({ ...formErrors, emails: [...formErrors.emails, ''] });
    }
  };

  const addPhoneNumber = () => {
    if (formData.phoneNumbers.length <= maxNumberOfPhoneNumbers) {
      setFormData({ ...formData, phoneNumbers: [...formData.phoneNumbers, ''] });
      setFormErrors({ ...formErrors, phoneNumbers: [...formErrors.phoneNumbers, ''] });
    }
  };

  const removeEmail = (index) => {
    if (formData.emails.length > 1) {
      const emails = [...formData.emails];
      const errors = [...formErrors.emails];
      emails.splice(index, 1);
      errors.splice(index, 1);
      setFormData({ ...formData, emails: emails });
      setFormErrors({ ...formErrors, emails: errors });
    }
  };

  const removePhoneNumber = (index) => {
    if (formData.phoneNumbers.length > 1) {
      const phoneNumbers = [...formData.phoneNumbers];
      const errors = [...formErrors.phoneNumbers];
      phoneNumbers.splice(index, 1);
      errors.splice(index, 1);
      setFormData({ ...formData, phoneNumbers: phoneNumbers });
      setFormErrors({ ...formErrors, phoneNumbers: errors });
    }
  };

  const handleEmailChange = (index, value) => {
    const emails = [...formData.emails];
    const errors = [...formErrors.emails];
    emails[index] = value;
    errors[index] = !value
      ? 'Email is required'
      : /^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,}$/i.test(value)
      ? ''
      : 'Invalid email address';
    setFormData({ ...formData, emails: emails });
    setFormErrors({ ...formErrors, emails: errors });
  };

  const handlePhoneNumberChange = (index, value) => {
    const phoneNumbers = [...formData.phoneNumbers];
    const errors = [...formErrors.phoneNumbers];
    phoneNumbers[index] = value;
    errors[index] = !value
      ? ''
      : /^\+?[\d\s\-()]*$/.test(value)
      ? ''
      : 'Invalid phone number';
    setFormData({ ...formData, phoneNumbers: phoneNumbers });
    setFormErrors({ ...formErrors, phoneNumbers: errors });
  };
  

  const handleInputChange = (e) => {
   
    const { name, value } = e.target;
    if(name == "shortTermAlertEnable"){
      setFormData({...formData, ["enableShortTermAlert"]: !formData.enableShortTermAlert, shortTermThreshold: ""});
      setFormErrors({ ...formErrors, [name]: !value ? 'This field is required' : '' });
    }
    else if(name == "longTermAlertEnable"){
      setFormData({...formData, ["enableLongTermAlert"]: !formData.enableLongTermAlert, longTermThreshold: ""});
      setFormErrors({ ...formErrors, [name]: !value ? 'This field is required' : '' });
    }
    else if(name =="uptimeSentinelEnable"){
      setFormData({...formData, ["uptimeSentinelEnable"]: !formData.uptimeSentinelEnable});
    }
    else if(name =="peakWeeklyCo2DigestEnable"){
      setFormData({...formData, ["peakWeeklyCo2DigestEnable"]: !formData.peakWeeklyCo2DigestEnable});
    }
    else{
      setFormData({ ...formData, [name]: value });
      setFormErrors({ ...formErrors, [name]: !value ? 'This field is required' : '' });
    }
  };

  function validateUUID(uuid) {
    const regex = /^[0-9a-fA-F]{8}-[0-9a-fA-F]{4}-[1-5][0-9a-fA-F]{3}-[89abAB][0-9a-fA-F]{3}-[0-9a-fA-F]{12}$/;
    return regex.test(uuid);
  }

  function validateMacAddress(mac) {
    const regex = /^([0-9A-F]{2}-){5}[0-9A-F]{2}$/;
    return regex.test(mac);
  }

  function validateShortTermThreshold(thresholdString){

    if(!formData.enableShortTermAlert)
      return true;

    let shortTermThresholdInt = parseInt(thresholdString);
    if(shortTermThresholdInt == "NaN")
      return false;

    //passed all tests now lets regex it
    const regex = /^([0-9]{1,4})$/;
    return regex.test(thresholdString);
  }

  function validateShortTermThresholdDuration(thresholdDurationString){

    if(!formData.enableShortTermAlert)
      return true;

    let shortTermThresholdDurationInt = parseInt(thresholdDurationString);

    if(shortTermThresholdDurationInt == "NaN")
      return false;

    if(shortTermThresholdDurationInt < 1 || shortTermThresholdDurationInt > 86400)
      return false;

    //passed all tests now lets regex it
    const regex = /^([0-9]{1,5})$/;
    return regex.test(thresholdDurationString);
  }

  function validateLongTermThreshold(thresholdString){

    if(!formData.enableLongTermAlert)
      return true;

    let longTermThresholdInt = parseInt(thresholdString);
    if(longTermThresholdInt == "NaN")
      return false;

    if(longTermThresholdInt > 1500 || longTermThresholdInt < 400)
      return false;

    //passed all tests now lets regex it
    const regex = /^([0-9]{1,4})$/;
    return regex.test(thresholdString);
  }

  function validateLongTermThresholdDuration(thresholdDurationString){

    if(!formData.enableLongTermAlert)
      return true;

    let longTermThresholdDurationInt = parseInt(thresholdDurationString);

    if(longTermThresholdDurationInt == "NaN")
      return false;

    if(longTermThresholdDurationInt < 2 || longTermThresholdDurationInt > 10)
      return false;

    //passed all tests now lets regex it
    const regex = /^([0-9]{1,5})$/;
    return regex.test(thresholdDurationString);
  }

  

  const validateForm = () => {
    const newErrors = {
      emails: formData.emails.map((email) => 
        !email ? 'Email is required' : !/^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,}$/i.test(email) ? 'Invalid email address' : ''
      ),
      companyName: !formData.companyName ? 'Company Name is required' : '',
      customerName: !formData.customerName ? 'Customer Name is required' : '',
      id: !validateUUID(formData.id) ? 'Warranty ID is required and must be a valid GUID' : '',
      macAddress: !validateMacAddress(formData.macAddress) ? 'MAC Address is required and must be in the form FF-FF-FF-FF-FF-FF' : '',
      shortTermThreshold: !validateShortTermThreshold(formData.shortTermThreshold) ? 'Threshold is required and must be an integer between 400 and 1500' : '',
      shortTermThresholdDuration: !validateShortTermThresholdDuration(formData.shortTermThresholdDuration) ? 'Duration is required and must be an integer between 1 and 86400' : '',
      longTermThreshold: !validateLongTermThreshold(formData.longTermThreshold) ? 'Threshold is required and must be an integer between 400 and 1500' : '',
      longTermThresholdDuration: !validateLongTermThresholdDuration(formData.longTermThresholdDuration) ? 'Duration is required and must be an integer between 2 and 10' : '',
      phoneNumbers: formData.phoneNumbers?.map((phoneNumber) => 
        !phoneNumber ? '' : !/^\+?[\d\s\-()]*$/.test(phoneNumber) ? 'Invalid phone number' : ''
      ),
    };

    formSubmittedRef.current = true;  // Mark the form as submitted
    setFormErrors(newErrors);

    return !newErrors.companyName &&
    !newErrors.customerName &&
    !newErrors.id &&
    !newErrors.macAddress &&
    newErrors.emails.every(emailError => !emailError) &&
    newErrors.phoneNumbers.every(phoneError => !phoneError || phoneError === '') &&
    (!formData.enableShortTermAlert || (formData.shortTermThreshold && !newErrors.shortTermThreshold && formData.shortTermThresholdDuration && !newErrors.shortTermThresholdDuration)) &&
    (!formData.enableLongTermAlert || (formData.longTermThreshold && !newErrors.longTermThreshold && formData.longTermThresholdDuration && !newErrors.longTermThresholdDuration));
  };

  useEffect(() => {
    if (formSubmittedRef.current) {
        const hasErrors = Object.values(formErrors).some(error => error);
        if (hasErrors) {
            scrollToFirstError();
        }
        formSubmittedRef.current = false;  // Reset the flag
    }
}, [formErrors]);  // This effect runs whenever formErrors change

  const scrollToFirstError = () => {
    if (formErrors.id) {
        idRef.current.scrollIntoView({ behavior: 'smooth' });
    } else if (formErrors.macAddress) {
        macAddressRef.current.scrollIntoView({ behavior: 'smooth' });
    } else if (formErrors.emails.some(error => error)) {
        const errorIndex = formErrors.emails.findIndex(error => error);
        emailRefs.current[errorIndex].scrollIntoView({ behavior: 'smooth' });
    } else if (formErrors.companyName) {
        companyNameRef.current.scrollIntoView({ behavior: 'smooth' });
    } else if (formErrors.customerName) {
        customerNameRef.current.scrollIntoView({ behavior: 'smooth' });
    } else if (formErrors.phoneNumbers.some(error => error)) {
        const errorIndex = formErrors.phoneNumbers.findIndex(error => error);
        phoneNumberRefs.current[errorIndex].scrollIntoView({ behavior: 'smooth' });
    } else if (formErrors.shortTermThreshold) {
        shortTermThresholdRef.current.scrollIntoView({ behavior: 'smooth' });
    } else if (formErrors.shortTermThresholdDuration) {
        shortTermThresholdDurationRef.current.scrollIntoView({ behavior: 'smooth' });
    } else if (formErrors.longTermThreshold) {
        longTermThresholdRef.current.scrollIntoView({ behavior: 'smooth' });
    } else if (formErrors.longTermThresholdDuration) {
        longTermThresholdDurationRef.current.scrollIntoView({ behavior: 'smooth' });
    }
    // Here you can continue with other error conditions and their corresponding refs, if any.
};

  // useEffect(() => {
  //   scrollToFirstError();
  // }, [formErrors]);

  const handleSubmit = async (e) => {
    e.preventDefault();
  
    if (!validateForm()) {
      return;
    }
  
    setLoading(true);
    setMessage(null);

    var params = JSON.parse(JSON.stringify(formData));
    params.mac_address = params.macAddress;

    delete params.macAddress;

    params.longTermThresholdDuration = (parseInt(params.longTermThresholdDuration) * 86400).toString();

    console.log("params is: ");
    console.log(params);

    try {
      const response = await axios.patch('https://vigxs3w3c4.execute-api.us-west-2.amazonaws.com/dev/warranty/register', params);

      console.log("setting message to: ");
      console.log(response.data);

      setMessage(response.data + " Please follow alert confirmation instructions on email and phone if applicable.");
      setIsError(false);
      setIsDisabled(true);
    } catch (error) {
      setMessage(error?.response?.data ? error.response.data + "\n Resolve this and then try again." : 'An error occurred');
      setIsError(true);
    } finally {
      setLoading(false);
    }
};

//https://warranty.xcspec.com/register?mac=FF-FF-FF-FF-FF-FF&id=bd94c470-aa03-11ec-99ea-d74fedf7ecff
//
  return (
    <Form onSubmit={handleSubmit} noValidate>
      <Form.Group style={{paddingBottom: "20px"}}>
        <Form.Label ref={idRef}>Warranty ID</Form.Label>
        <Form.Control
          type="text"
          
          name="id"
          value={formData.id}
          maxLength={50}
          isInvalid={!!formErrors.id}
          onChange={handleInputChange}
          disabled={isDisabled}
        />
        <Form.Control.Feedback type="invalid" className="error-message">
          {formErrors.id}
        </Form.Control.Feedback>
      </Form.Group>

      <Form.Group style={{paddingBottom: "20px"}}>
        <Form.Label ref={macAddressRef}>MAC Address</Form.Label>
        <Form.Control
          type="text"
          name="macAddress"
          value={formData.macAddress}
          maxLength={17}
          isInvalid={!!formErrors.macAddress}
          onChange={handleInputChange}
          disabled={isDisabled}
        />
        <Form.Control.Feedback type="invalid" className="error-message">
          {formErrors.macAddress}
        </Form.Control.Feedback>
      </Form.Group>

      <Form.Group style={{paddingBottom: "20px"}}>
        <Form.Label ref={companyNameRef}>Your Company Name</Form.Label>
        <Form.Control
          type="text"
          name="companyName"
          value={formData.companyName}
          maxLength={50}
          isInvalid={!!formErrors.companyName}
          onChange={handleInputChange}
          disabled={isDisabled}
        />
        <Form.Control.Feedback type="invalid" className="error-message">
          {formErrors.companyName}
        </Form.Control.Feedback>
      </Form.Group>

      <Form.Group style={{paddingBottom: "20px"}}>
        <Form.Label ref={customerNameRef}>Name of person to receive alerts</Form.Label>
        <Form.Control
          type="text"
          name="customerName"
          value={formData.customerName}
          maxLength={50}
          isInvalid={!!formErrors.customerName}
          onChange={handleInputChange}
          disabled={isDisabled}
        />
        <Form.Control.Feedback type="invalid" className="error-message">
          {formErrors.customerName}
        </Form.Control.Feedback>
      </Form.Group>

      <Form.Group style={{paddingBottom: "20px"}}>
        <Form.Label>Email of responsible person for alerts</Form.Label>
        {formData.emails.map((email, index) => (
          <div key={index}>
            <Form.Control
              type="email"
              name={`emails[${index}]`}
              ref={(el) => emailRefs.current[index] = el}
              value={email}
              maxLength={50}
              isInvalid={!!formErrors.emails[index]}
              onChange={(e) => handleEmailChange(index, e.target.value)}
              disabled={isDisabled}
            />
            <Form.Control.Feedback type="invalid" className="error-message">
              {formErrors.emails[index]}
            </Form.Control.Feedback>
            {/* {formData.emails.length > 1 && (
              <Button variant="danger" style={{marginTop: "10px", marginBottom: "10px"}} onClick={() => removeEmail(index)} disabled={isDisabled}>Remove Email</Button>
            )} */}
          </div>
        ))}
        {/* {formData.emails.length < maxNumberOfEmails && <Button variant="primary" style={{marginTop: "10px"}} onClick={addEmail} disabled={isDisabled}>Add Email</Button>} */}
      </Form.Group>

      <Form.Group style={{paddingBottom: "20px"}}>  {/* Optional phone number field */}
        <Form.Label>Phone Number of responsible person for alerts (optional)</Form.Label>
        {formData.phoneNumbers.map((phoneNumber, index) => (
          <div key={index}>
            <Form.Control
              type="tel"
              ref={(el) => phoneNumberRefs.current[index] = el}
              name={`phoneNumber[${index}]`}
              value={phoneNumber}
              maxLength={50}
              isInvalid={!!formErrors.phoneNumbers[index]}
              onChange={(e) => handlePhoneNumberChange(index, e.target.value)}
              disabled={isDisabled}
            />

            {/* <Form.Control
              type="tel"
              name="phoneNumber"
              value={formData.phoneNumber}
              onChange={handleInputChange}
              disabled={isDisabled}
            /> */}

            <Form.Control.Feedback type="invalid" className="error-message">
              {formErrors.phoneNumbers[index]}
            </Form.Control.Feedback>
            {formData.phoneNumbers.length > 1 && (
              <Button variant="danger" style={{marginTop: "10px", marginBottom: "10px"}} onClick={() => removePhoneNumber(index)} disabled={isDisabled}>Remove Phone Number</Button>
            )}
          </div>
        ))}
        {/* {formData.phoneNumbers.length < maxNumberOfPhoneNumbers && <Button variant="primary" style={{marginTop: "10px"}} onClick={addPhoneNumber} disabled={isDisabled}>Add Phone Number</Button>} */}
      </Form.Group>

      <hr className="section-divider" style={{border: "none", borderTop: "1px solid #777", paddingBottom: "10px"}}/>
      
      <div style={{paddingBottom: "15px"}}>
        <h3>Immediate CO2 Alert (optional)</h3>
        <span className="question-mark">Receive an alert if the CO2 surpasses the PPM threshold for the specified duration consistently. Receive a recovery notification when the alert condition no longer exists.</span>
        {/* <div className="tooltip-content" style={{display: "none"}}>
          This is the tooltip content.
        </div> */}
      </div>



      <Form.Group style={{paddingBottom: "20px"}}>
        <Form.Check
          type="checkbox"
          name="shortTermAlertEnable"
          label={`Enable`}
          onChange={handleInputChange}
          checked={formData.enableShortTermAlert}
          // disabled={isDisabled}
        />
      </Form.Group>


      <Form.Group style={{paddingBottom: "20px"}}>
        <Form.Label ref={shortTermThresholdRef}>CO2 Threshold (PPM)</Form.Label>
        <Form.Control
          type="text"
          name="shortTermThreshold"
          value={formData.shortTermThreshold}
          maxLength={50}
          isInvalid={!!formErrors.shortTermThreshold}
          onChange={handleInputChange}
          disabled={!formData.enableShortTermAlert}
        />
        <Form.Control.Feedback type="invalid" className="error-message">
          {formErrors.shortTermThreshold}
        </Form.Control.Feedback>
      </Form.Group>

      <Form.Group style={{paddingBottom: "20px"}}>
        <Form.Label ref={shortTermThresholdDurationRef}>Duration (seconds)</Form.Label>
        <Form.Control
          type="text"
          name="shortTermThresholdDuration"
          value={formData.shortTermThresholdDuration}
          maxLength={50}
          isInvalid={!!formErrors.shortTermThresholdDuration}
          onChange={handleInputChange}
          disabled={!formData.enableShortTermAlert}
        />
        <Form.Control.Feedback type="invalid" className="error-message">
          {formErrors.shortTermThresholdDuration}
        </Form.Control.Feedback>
      </Form.Group>

      <hr className="section-divider" style={{border: "none", borderTop: "1px solid #777", paddingBottom: "10px"}}/>

      <div style={{paddingBottom: "15px"}}>
        <h3>Consecutive Days Over PPM Alert (optional)</h3>
        <span className="question-mark">Receive an alert if the CO2 surpasses the PPM threshold for the specified number of days consistently. Receive a recovery notification when the alert condition no longer exists.</span>
        {/* <div className="tooltip-content" style={{display: "none"}}>
          This is the tooltip content.
        </div> */}
      </div>

      <Form.Group style={{paddingBottom: "20px"}}>
        <Form.Check
          type="checkbox"
          name="longTermAlertEnable"
          label={`Enable`}
          onChange={handleInputChange}
          checked={formData.enableLongTermAlert}
          // disabled={isDisabled}
        />
        <Form.Control.Feedback type="invalid" className="error-message">
          {formErrors.longTermThreshold}
        </Form.Control.Feedback>
      </Form.Group>


      <Form.Group style={{paddingBottom: "20px"}}>
        <Form.Label ref={longTermThresholdRef}>CO2 Threshold (PPM)</Form.Label>
        <Form.Control
          type="text"
          name="longTermThreshold"
          value={formData.longTermThreshold}
          maxLength={50}
          isInvalid={!!formErrors.longTermThreshold}
          onChange={handleInputChange}
          disabled={!formData.enableLongTermAlert}
        />
        <Form.Control.Feedback type="invalid" className="error-message">
          {formErrors.longTermThreshold}
        </Form.Control.Feedback>
      </Form.Group>

      <Form.Group style={{paddingBottom: "20px"}}>
        <Form.Label ref={longTermThresholdDurationRef}>Duration (days)</Form.Label>
        <Form.Control
          type="text"
          name="longTermThresholdDuration"
          value={formData.longTermThresholdDuration}
          maxLength={50}
          isInvalid={!!formErrors.longTermThresholdDuration}
          onChange={handleInputChange}
          disabled={!formData.enableLongTermAlert}
        />
        <Form.Control.Feedback type="invalid" className="error-message">
          {formErrors.longTermThresholdDuration}
        </Form.Control.Feedback>
      </Form.Group>

      <hr className="section-divider" style={{border: "none", borderTop: "1px solid #777", paddingBottom: "10px"}}/>

      <div style={{paddingBottom: "15px"}}>
        <h3>Uptime Monitoring (optional)</h3>
        <span className="question-mark">Real-time online status monitoring of your device. Receive alerts when the device goes offline or back online.</span>
        {/* <div className="tooltip-content" style={{display: "none"}}>
          This is the tooltip content.
        </div> */}
      </div>

      <Form.Group style={{paddingBottom: "10px"}}>
        <Form.Check
          type="checkbox"
          name="uptimeSentinelEnable"
          label={`Enable`}
          onChange={handleInputChange}
          checked={formData.uptimeSentinelEnable}
          // disabled={isDisabled}
        />
      </Form.Group>


      <hr className="section-divider" style={{border: "none", borderTop: "1px solid #777", paddingBottom: "10px"}}/>


      <div style={{paddingBottom: "15px"}}>
        <h3>Peak CO2 Weekly Digest (optional)</h3>
        <span className="question-mark">Receive a weekly email every Sunday listing the peak CO2 measurements for each day of the past week.</span>
        {/* <div className="tooltip-content" style={{display: "none"}}>
          This is the tooltip content.
        </div> */}
      </div>

      <Form.Group style={{paddingBottom: "10px"}}>
        <Form.Check
          type="checkbox"
          name="peakWeeklyCo2DigestEnable"
          label={`Enable`}
          onChange={handleInputChange}
          checked={formData.peakWeeklyCo2DigestEnable}
          // disabled={isDisabled}
        />
      </Form.Group>


      <hr className="section-divider" style={{border: "none", borderTop: "1px solid #777", paddingBottom: "10px"}}/>

      {message && (
        <Alert transition={false} variant={isError ? 'danger' : 'success'} style={{marginTop: "10px"}}>
          <FaExclamationTriangle /> {message}
        </Alert>
      )}

      <Button variant="primary" type="submit" disabled={loading || isDisabled}>
        {loading ? 'Loading...' : 'Submit All'}
      </Button>

      {loading ? (
        <Spinner animation="border" role="status" />
      ) : null}
    </Form>
  );
};

export default WarrantyRegistration;